var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"py-3 px-3",attrs:{"justify-space-between":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',[_c('dsb-dashboard-report-downloader',{attrs:{"suffix":_vm.suffix}})],1),_c('v-layout',[_c('div',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'orderItems',
        query: {
          suffix: _vm.suffix,
          pageNum: '1',
          state: 'Active',
        },
      },"target":"_blank"}},[_c('dsb-dashboard-statistics-card',{attrs:{"title":"Active Order Items","icon":"view_list"}},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.statistics.activeOrderItemsCount)+" ")])])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'rules',
        query: {
          suffix: _vm.suffix,
          pageNum: '1',
          status: 'Active',
        },
      },"target":"_blank"}},[_c('dsb-dashboard-statistics-card',{staticClass:"mt-4",attrs:{"title":"Rules Enabled","icon":"assignment_turned_in"}},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.statistics.activeOrderFiltersCount)+" ")])])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'profitMachines',
        query: {
          suffix: _vm.suffix,
          pageNum: '1',
          state: 'Enabled',
        },
      },"target":"_blank"}},[_c('dsb-dashboard-statistics-card',{staticClass:"mt-4",attrs:{"title":"Machines Enabled","icon":"scanner"}},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.statistics.activeMachinesCount)+" ")])])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'orderItems',
        query: {
          suffix: _vm.suffix,
          pageNum: '1',
          state: 'Active',
        },
      },"target":"_blank"}},[_c('dsb-dashboard-statistics-card',{staticClass:"mt-4",attrs:{"title":"Last Added Order Item","icon":"timer"}},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getLastTimeStr(_vm.statistics.lastAddedOrderItemAgo))+" ")])])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'pressSheets',
            query: {
              suffix: _vm.suffix,
              pageNum: '1',
            },
          },"target":"_blank"}},[_c('dsb-dashboard-statistics-card',{staticClass:"mt-4",attrs:{"title":"Last Created Press Sheet","icon":"timer"}},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getLastTimeStr(_vm.statistics.lastCreatedPressSheetAgo))+" ")])])],1)],1),_c('div',{staticStyle:{"width":"83%"}},[_c('dsb-dashboard-chart',{staticClass:"mt-0",staticStyle:{"height":"563px"},attrs:{"items":_vm.chart,"loading":_vm.chartLoading},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-flex',{attrs:{"sm3":""}},[_c('vbt-date-picker',{attrs:{"clearable":false,"custom-input-class":"vbt-hide-form-control-details","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)]},proxy:true}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }