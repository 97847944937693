<template>
  <vbt-content-box
    title="Order Items Chart"
  >
    <template slot="toolbar">
      <slot name="toolbar" />
    </template>

    <v-layout justify-space-between>
      <strong>
        Added
      </strong>

      <strong>
        Total: {{ totalAddedWithoutRule }}/{{ totalAddedWithRule }}
      </strong>
    </v-layout>
    <v-divider class="mt-1" />

    <dsb-chart-wrapper :tooltip-handler="tooltipHandler(addedItemsTitles)">
      <template #default="{ options }">
        <div
          v-if="loading"
          style="height: 218px;"
        >
          <vbt-loading
            type="small"
          />
        </div>
        <v-bar
          v-else
          :data="addedChart"
          :legend-visible="false"
          :settings="addedChartSettings"
          :extend="options.chartExtend"
          :text-style="options.textStyle"
          :colors="['#19d4ae', '#00ae45']"
          height="290px"
          class="my-n9"
          width="100%"
        />
      </template>
    </dsb-chart-wrapper>

    <v-layout justify-space-between>
      <strong>
        Processed
      </strong>

      <strong>
        Total: {{ totalProcessed }}
      </strong>
    </v-layout>
    <v-divider class="mt-1" />

    <dsb-chart-wrapper :tooltip-handler="tooltipHandler(processedItemsTitles)">
      <template #default="{ options }">
        <div
          v-if="loading"
          style="height: 217px;"
        >
          <vbt-loading
            type="small"
          />
        </div>
        <v-bar
          v-else
          :data="processedChart"
          :legend-visible="false"
          :extend="options.chartExtend"
          :text-style="options.textStyle"
          :colors="['#19d4ae']"
          height="290px"
          class="my-n9"
          width="100%"
        />
      </template>
    </dsb-chart-wrapper>
  </vbt-content-box>
</template>

<script>
import VBar from 'v-charts/lib/bar.common';

import { formatDisplayDate } from '@helpers';

const addedItemsTitles = Object.freeze({
  addedWithoutRule: 'Added Without Rule',
  addedWithRule: 'Added With Rule',
});

const processedItemsTitles = Object.freeze({
  processed: 'Processed',
});

export default {
  name: 'DsbDashboardChart',

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: Boolean,
  },

  components: {
    VBar,
  },

  data() {
    return {
      addedItemsTitles,
      processedItemsTitles,
    };
  },

  computed: {
    totalAddedWithoutRule() {
      return this.items.reduce((acc, { addedWithoutRule: n }) => (n || 0) + acc, 0);
    },

    totalAddedWithRule() {
      return this.items.reduce((acc, { addedWithRule: n }) => (n || 0) + acc, 0);
    },

    totalProcessed() {
      return this.items.reduce((acc, { processed: n }) => (n || 0) + acc, 0);
    },

    chartRows() {
      return JSON.parse(JSON.stringify(this.items))
        .sort(({ date: d1 }, { date: d2 }) => new Date(d1) - new Date(d2))
        .map((v) => ({
          ...v,
          date: formatDisplayDate(
            v.date,
            { dateFormat: 'dd MMM yyyy' },
          ),
        }));
    },

    addedChart() {
      return {
        columns: ['date', 'addedWithoutRule', 'addedWithRule'],
        rows: this.chartRows,
      };
    },

    addedChartSettings() {
      return {
        stack: {
          statuses: ['addedWithoutRule', 'addedWithRule'],
        },
      };
    },

    processedChart() {
      return {
        columns: ['date', 'processed'],
        rows: this.chartRows,
      };
    },
  },

  methods: {
    tooltipHandler(titles) {
      return (values) => values.map((v) => ({ ...v, title: titles[v.title] }));
    },
  },
};
</script>
