<template>
  <v-card
    height="100px"
    width="270px"
    rounded
  >
    <v-layout
      class="py-4 px-4"
      align-center
      fill-height
    >
      <v-sheet
        rounded
        width="60"
        height="60"
        elevation="6"
        color="success"
      >
        <v-layout
          justify-center
          align-center
          fill-height
        >
          <v-icon
            color="white"
            large
          >
            {{ icon }}
          </v-icon>
        </v-layout>
      </v-sheet>

      <v-layout
        class="ml-3"
        align-end
        column
      >
        <span class="subtitle-2">
          {{ title }}
        </span>

        <v-divider
          style="width: 100%;"
          class="my-1"
        />

        <slot />
      </v-layout>
    </v-layout>
  </v-card>

<!--  <v-card-->
<!--    class="mt-5 mx-2"-->
<!--    height="130px"-->
<!--    width="310px"-->
<!--    rounded-->
<!--  >-->
<!--    <v-card-title>-->
<!--      <v-sheet-->
<!--        rounded-->
<!--        width="60"-->
<!--        height="60"-->
<!--        elevation="6"-->
<!--        color="success"-->
<!--        class="mt-n7 ml-1"-->
<!--      >-->
<!--        <v-layout-->
<!--          justify-center-->
<!--          align-center-->
<!--          fill-height-->
<!--        >-->
<!--          <v-icon-->
<!--            color="white"-->
<!--            large-->
<!--          >-->
<!--            {{ icon }}-->
<!--          </v-icon>-->
<!--        </v-layout>-->
<!--      </v-sheet>-->

<!--      <strong class="mt-n3 ml-5 subtitle-1">-->
<!--        {{ title }}-->
<!--      </strong>-->
<!--    </v-card-title>-->

<!--    <v-card-text>-->
<!--      sdas-->
<!--    </v-card-text>-->
<!--  </v-card>-->
</template>

<script>
export default {
  name: 'DsbDashboardStatisticsCard',

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
