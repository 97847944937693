<template>
  <v-layout
    justify-space-between
    class="py-3 px-3"
  >
    <v-layout column>
      <v-layout>
        <dsb-dashboard-report-downloader :suffix="suffix" />
      </v-layout>

      <v-layout>
        <div>
          <router-link
            :to="{
          name: 'orderItems',
          query: {
            suffix,
            pageNum: '1',
            state: 'Active',
          },
        }"
            target="_blank"
            style="text-decoration: none;"
          >
            <dsb-dashboard-statistics-card
              title="Active Order Items"
              icon="view_list"
            >
          <span class="subtitle-1 font-weight-bold">
            {{ statistics.activeOrderItemsCount }}
          </span>
            </dsb-dashboard-statistics-card>
          </router-link>

          <router-link
            :to="{
          name: 'rules',
          query: {
            suffix,
            pageNum: '1',
            status: 'Active',
          },
        }"
            target="_blank"
            style="text-decoration: none;"
          >
            <dsb-dashboard-statistics-card
              title="Rules Enabled"
              icon="assignment_turned_in"
              class="mt-4"
            >
          <span class="subtitle-1 font-weight-bold">
            {{ statistics.activeOrderFiltersCount }}
          </span>
            </dsb-dashboard-statistics-card>
          </router-link>

          <router-link
            :to="{
          name: 'profitMachines',
          query: {
            suffix,
            pageNum: '1',
            state: 'Enabled',
          },
        }"
            target="_blank"
            style="text-decoration: none;"
          >
            <dsb-dashboard-statistics-card
              title="Machines Enabled"
              icon="scanner"
              class="mt-4"
            >
          <span class="subtitle-1 font-weight-bold">
            {{ statistics.activeMachinesCount }}
          </span>
            </dsb-dashboard-statistics-card>
          </router-link>

          <router-link
            :to="{
          name: 'orderItems',
          query: {
            suffix,
            pageNum: '1',
            state: 'Active',
          },
        }"
            target="_blank"
            style="text-decoration: none;"
          >
            <dsb-dashboard-statistics-card
              title="Last Added Order Item"
              icon="timer"
              class="mt-4"
            >
          <span class="subtitle-1 font-weight-bold">
            {{ getLastTimeStr(statistics.lastAddedOrderItemAgo) }}
          </span>
            </dsb-dashboard-statistics-card>
          </router-link>

          <router-link
            :to="{
              name: 'pressSheets',
              query: {
                suffix,
                pageNum: '1',
              },
            }"
            target="_blank"
            style="text-decoration: none;"
          >
            <dsb-dashboard-statistics-card
              title="Last Created Press Sheet"
              icon="timer"
              class="mt-4"
            >
          <span class="subtitle-1 font-weight-bold">
            {{ getLastTimeStr(statistics.lastCreatedPressSheetAgo) }}
          </span>
            </dsb-dashboard-statistics-card>
          </router-link>
        </div>

        <div style="width: 83%;">
          <dsb-dashboard-chart
            :items="chart"
            :loading="chartLoading"
            style="height: 563px;"
            class="mt-0"
          >
            <template #toolbar>
              <v-flex sm3>
                <vbt-date-picker
                  v-model="dates"
                  :clearable="false"
                  custom-input-class="vbt-hide-form-control-details"
                  range
                />
              </v-flex>
            </template>
          </dsb-dashboard-chart>
        </div>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import { createTicker, getDatesRangeByLastDays, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import DsbDashboardStatisticsCard from '../_components/DsbDashboardStatisticsCard.vue';
import DsbDashboardChart from '../_components/DsbDashboardChart.vue';
import DsbDashboardReportDownloader from '../_components/DsbDashboardReportDownloader.vue';

import {
  namespace as DashboardNamespace,
  ActionTypes as DashboardActionTypes,
} from '../store.types';

const dataLoadingOptions = {
  dependenciesChangeHandler: 'resetTicker',
  getterName: 'getDashboard',
  parameterize: true,
  searchable: false,
  paged: false,
};

const dashboardTicker = createTicker();

export default {
  name: 'DsbDashboard',

  components: {
    DsbDashboardStatisticsCard,
    DsbDashboardChart,
    DsbDashboardReportDownloader,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  data() {
    return {
      statisticsLoading: false,
      chartLoading: false,

      dates: getDatesRangeByLastDays(7),
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapState(DashboardNamespace, ['statistics', 'chart']),
  },

  watch: {
    dates: {
      handler(dates) {
        this.getChart({ dates });
        this.resetTicker();
      },
      deep: true,
    },
  },

  created() {
    dashboardTicker.subscribeToTicker(this.getDashboard.bind(this, { withLoadingFlag: false }));
    dashboardTicker.loadTicker({ intervalMSec: 30000 });
  },

  beforeDestroy() {
    dashboardTicker.destroyTicker();
  },

  methods: {
    ...mapActions(DashboardNamespace, {
      getStatistics(dispatch, { suffix = this.suffix, withLoadingFlag = true } = {}) {
        this.wrapToLoadingFn({
          req: dispatch.bind({}, DashboardActionTypes.GET_DASHBOARD_STATISTICS, { suffix }),
          loadingFlagName: withLoadingFlag && 'statisticsLoading',
        });
      },

      getChart(dispatch, {
        suffix = this.suffix,
        dates = this.dates,
        withLoadingFlag = true,
      } = {}) {
        const [from, to] = dates;

        this.wrapToLoadingFn({
          req: dispatch.bind({}, DashboardActionTypes.GET_DASHBOARD_CHART, {
            suffix,
            from,
            to: `${to}T23:59:59`,
          }),
          loadingFlagName: withLoadingFlag && 'chartLoading',
        });
      },
    }),

    getDashboard(params = {}) {
      this.getStatistics(params);
      this.getChart(params);
    },

    resetTicker() {
      dashboardTicker.stopTicker();
      dashboardTicker.loadTicker({ intervalMSec: 30000 });
    },

    getLastTimeStr(config) {
      const keys = ['days', 'hours', 'minutes', 'seconds'];

      return keys.reduce((acc, key) => {
        const { [key]: v } = config;

        if (acc === '-' && v) {
          return `${v} ${v !== 1 ? key : key.slice(0, key.length - 1)} ago`;
        }

        return acc;
      }, '-');
    },

    wrapToLoadingFn,
  },
};
</script>
