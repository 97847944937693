<template>
  <v-btn
    :loading="loading"
    color="success"
    width="270px"
    @click="downloadCSV"
  >
    Items Without Rules
  </v-btn>
</template>

<script>
import { wrapToLoadingFn, downloadFileWithDisposition, CSV_FILE_TYPE } from '@helpers';

import { DashboardApiService } from '../_services/dashboard.api.service';

export default {
  name: 'DsbDashboardReportDownloader',

  props: {
    suffix: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    downloadCSV() {
      this.wrapToLoadingFn({
        req: DashboardApiService.downloadReportCSV.bind(null, { suffix: this.suffix }),
        loadingFlagName: 'loading',
        onSuccess: (r) => downloadFileWithDisposition({
          ...r,
          type: CSV_FILE_TYPE,
          defaultFileName: 'ItemsWithoutRules.csv',
        }),
      });
    },

    wrapToLoadingFn,
  },
};
</script>
